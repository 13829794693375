<template>
  <v-dialog v-model="modalShow" persistent max-width="500px" scrollable class="z-index-lg">
    <v-card tile>
      <v-card-title class="pa-0 ma-0" style="z-index: 10000 !important;">
        <v-btn icon @click="cerrar()" style="position: absolute; right:15px; top: 5px">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-overlay :value="loadingHorario" color="white" absolute opacity=".8">
        <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-content class="text-center">
              <span class="text-h4 font-weight-bold primary--text">Horario de Visita</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <validation-observer ref="HORARIO_FORM" tag="div">
          <v-row class="d-block">
            <v-col cols="12">
              <label-form text="Fecha de inicio" required />
              <validation-provider v-slot="{ errors }" name="Fecha" vid="fecha" rules="required">
                <fecha-input v-model="horarioInfo.fecha_inicio"
                  color="blue-grey lighten-5" :error="errors[0]" width-input="220px" />
                  <!-- :picker-initial="min_date_input"
                :min="min_date_input"
                  :picker-initial="min_date_input"
                :min="horarioInfo.fecha_inicio || min_date_input" -->
              </validation-provider>
            </v-col>
          </v-row>

          <v-row class="d-block" v-if="this.dataVisita.newSeller === true">
            <v-col cols="9">
              <label-form text="Vendedor" required />
              <v-select v-model="selected" :hint="`${sellersList.ven_des}, ${sellersList.co_ven}`" :items="sellersList"
                item-text="ven_des" item-value="co_ven" label="Seleccionar vendedor" return-object single-line dense
                solo required></v-select>
            </v-col>
          </v-row>

          <v-row class="d-block">
            <v-col cols="12" class="pt-0">
              <label-form text="Frecuencia de Visita" required />
              <validation-provider name="Frecuencia" vid="horarioInfo.frecuencia" rules="required" v-slot="{ errors }">
                <v-radio-group v-model="horarioInfo.frecuencia" row :error-messages="errors[0]" class="mt-1">
                  <v-radio label="Semanal" value="semanal" color="icono" />
                  <v-radio label="Quincinal" value="quincenal" color="icono" />
                  <v-radio label="Mensual" value="mensual" color="icono" />
                </v-radio-group>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <label-form text="Seleccione los días de la Semana que puede ser visitado" required />
              <v-row class="mt-4">
                <v-col cols="4" class="py-0">
                  <v-switch v-model="horarioInfo.lunes" inset dense class="mt-0 pt-1" color="icono" true-value="1"
                    false-value="0" :error="validateDay" @change="validateDay = false">
                    <template #label>
                      <span class="primary--text mt-n1">Lunes</span>
                    </template>
                  </v-switch>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-switch v-model="horarioInfo.martes" inset dense class="mt-0 pt-1" color="icono" true-value="1"
                    false-value="0" :error="validateDay" @change="validateDay = false">
                    <template #label>
                      <span class="primary--text mt-n1">Martes</span>
                    </template>
                  </v-switch>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-switch v-model="horarioInfo.miercoles" inset dense class="mt-0 pt-1" color="icono" true-value="1"
                    false-value="0" :error="validateDay" @change="validateDay = false">
                    <template #label>
                      <span class="primary--text mt-n1">Miércoles</span>
                    </template>
                  </v-switch>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-switch v-model="horarioInfo.jueves" inset dense class="mt-0 pt-1" color="icono" true-value="1"
                    false-value="0" :error="validateDay" @change="validateDay = false">
                    <template #label>
                      <span class="primary--text mt-n1">Jueves</span>
                    </template>
                  </v-switch>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-switch v-model="horarioInfo.viernes" inset dense class="mt-0 pt-1" color="icono" value="1"
                    false-value="0" true-value="1" :error="validateDay" @change="validateDay = false">
                    <template #label>
                      <span class="primary--text mt-n1">Viernes</span>
                    </template>
                  </v-switch>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-switch v-model="horarioInfo.sabado" inset dense class="mt-0 pt-1" color="icono" true-value="1"
                    false-value="0" :error="validateDay" @change="validateDay = false">
                    <template #label>
                      <span class="primary--text mt-n1">Sábado</span>
                    </template>
                  </v-switch>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-switch v-model="horarioInfo.domingo" inset dense class="mt-0 pt-1" color="icono" true-value="1"
                    false-value="0" :error="validateDay" @change="validateDay = false">
                    <template #label>
                      <span class="primary--text mt-n1">Domingo</span>
                    </template>
                  </v-switch>
                </v-col>
                <v-col v-if="validateDay" cols="12" class="py-0 my-0">
                  <span class="red--text px-0 caption">Debe seleccionar mínimo un día de la Semana</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-3">
        <v-btn tile outlined color="blue-grey" class="px-6 mx-3" :small="$vuetify.breakpoint.mobile" @click="cerrar()">
          Cancelar
        </v-btn>
        <v-btn tile color="success" class="px-8" :small="$vuetify.breakpoint.mobile" @click="addHorario">
          <v-icon dark left>mdi-account-edit-outline</v-icon>
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { get } from 'vuex-pathify'
import moment from 'moment'
  export default {
  name:'ModalHorario',
  components:{
     FechaInput: () => import(
      /* webpackChunkName: "fecha-input" */
      '@/widgets/FechaInput.vue'
    )
  },
  props:{
    value: Boolean,
    dataVisita:{
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      selected: '',
      sellersListOriginal: [],
      sellersList: [],
      modalShow: this.value,
      loadingHorario: false,
      frecuencia: '',
      horarioInfo: {
        frecuencia: '',
        lunes: '',
        martes: '',
        miercoles: '',
        jueves: '',
        viernes: '',
        sabado: '',
        domingo: '',
        fecha_inicio: '',
      },
      days:[
        {day: 'Lunes', value: 'lunes'},
        {day: 'Martes', value: 'martes'},
        {day: 'Miércoles', value: 'mie'},
        {day: 'Jueves', value: 'jue'},
        {day: 'Viernes', value: 'vie'},
        {day: 'Sábado', value: 'sab'},
        {day: 'Domingo', value: 'dom'},
      ],
      validateDay: false,
      min_date_input: moment().format('YYYY-MM-DD'),
    }
  },
  watch: {
    modalShow (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.modalShow = val
      this.horarioInfo = Object.assign({},this.dataVisita);
    },
    // horarioInfo(value){
    //   this.validateDay = this.validateDiaSemana(value);
    // }
  },
  methods: {
    cerrar () {
      this.modalShow = false;
      this.horarioInfo = '';
      this.$refs.HORARIO_FORM.reset();
    },
    validateDiaSemana (data) {
      let {frecuencia, id, co_cli, fecha_inicio, ...dias} = data;
      return Object.values(dias).length > 0 ? Object.values(dias).some(item => item != 0) : false;
    },
    async addHorario () {
      const valid = await this.$refs.HORARIO_FORM.validate();
      const validDay = this.validateDiaSemana(this.horarioInfo);
      this.validateDay = !validDay;
      if(valid && validDay) {
        try {
          this.loadingHorario= true;
          delete this.horarioInfo.created_at;
          delete this.horarioInfo.updated_at;
          delete this.horarioInfo.ven_des;
          delete this.horarioInfo.id;

          if (this.dataVisita.newSeller === true) {
            console.log(this.selected);
            if(!this.selected.co_ven){
              this.loadingHorario = false;
              this.$root.$showAlert('Debe seccionar un vendedor', 'success');
              return;
            };
            this.horarioInfo.co_cli = this.id_cliente;
            this.horarioInfo.co_ven = this.selected.co_ven;
            this.horarioInfo.portafolio = true;
          } else {
            if (this.dataVisita.portafolio === true) {
              this.horarioInfo.portafolio = true;
            } else {
              delete this.horarioInfo.portafolio;
            }
          }

          delete this.horarioInfo.newSeller;

          //console.log(this.horarioInfo); this.loadingHorario = false; return;

          await this.$api.post(`clientes/${this.horarioInfo.co_cli}/v2/visitas`, this.horarioInfo);

          this.cerrar();
          this.$parent.$emit('change');
          //this.$parent.$emit('updateSellers');
          this.$root.$showAlert('El horario del Cliente se actualizó Satisfactoriamente', 'success');
        } catch (error) {
          this.$root.$showAlert(
            'Lo sentimos, hubo un error al intentar actualizar el Horario de Visita.',
              'error'
            );
            this.cerrar();
        }
        this.loadingHorario = false;
        this.validateDay = false;
      }
    },
    async getSellers() {
      this.loadingPermisos = true;
      await this.$api.get('users')
        .then((data) => {
          this.sellersListOriginal = data.data.data;

          this.sellersListOriginal.map((seller) => {
            if (seller.user != null) {
              this.sellersList.push(seller);
            }
          })
        })
        .catch((error) => {
          this.$root.$showAlert('Lo siento, hubo un error al intentar obtener los permisos.',
            error)
        });
      this.loadingPermisos = false;
    },
  },
  computed: {
    id_cliente: get('route/params@cod'),
  },
  async mounted(){
    await this.getSellers();
  },
}
</script>
<style lang="sass">
.z-index-lg
  z-index: 1000 !important
</style>
